// Generated by Framer (e4277c8)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {MjSGqZNcr: {hover: true}};

const serializationHash = "framer-eQEYw"

const variantClassNames = {MjSGqZNcr: "framer-v-j7znd0"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, tapStart, title, width, ...props}) => { return {...props, Nv26bfu6G: title ?? props.Nv26bfu6G ?? "text", Y7o357GID: tapStart ?? props.Y7o357GID} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;tapStart?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Nv26bfu6G, Y7o357GID, GOvBLr491Bja_OBDRD, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "MjSGqZNcr", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapStart7c59ct = activeVariantCallback(async (...args) => {
setGestureState({isPressed: true})
if (Y7o357GID) {const res = await Y7o357GID(...args);
if (res === false) return false;}
})

const onTapStartcc3842 = activeVariantCallback(async (...args) => {
setVariant("I_yKRgu9G")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-j7znd0", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"MjSGqZNcr"} onTapStart={onTapStart7c59ct} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"MjSGqZNcr-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Link href={GOvBLr491Bja_OBDRD} openInNewTab={false} smoothScroll><motion.a className={"framer-1w7njkr framer-12p6cr2"} data-highlight layoutDependency={layoutDependency} layoutId={"CwHZQult9"} onTapStart={onTapStartcc3842} style={{backgroundColor: "rgb(47, 131, 222)", borderBottomLeftRadius: 6, borderBottomRightRadius: 6, borderTopLeftRadius: 6, borderTopRightRadius: 6}} variants={{"MjSGqZNcr-hover": {backgroundColor: "rgb(28, 95, 166)"}}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0JyYW5kb24gVGV4dCBCb2xk", "--framer-font-family": "\"Brandon Text Bold\", \"Brandon Text Bold Placeholder\", sans-serif", "--framer-line-height": "1.5em", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Choose Filter</motion.p></React.Fragment>} className={"framer-18w0uxe"} fonts={["CUSTOM;Brandon Text Bold"]} layoutDependency={layoutDependency} layoutId={"MP_tcXN8b"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={Nv26bfu6G} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-eQEYw.framer-12p6cr2, .framer-eQEYw .framer-12p6cr2 { display: block; }", ".framer-eQEYw.framer-j7znd0 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; padding: 0px; position: relative; width: min-content; }", ".framer-eQEYw .framer-1w7njkr { align-content: center; align-items: center; cursor: pointer; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 6px; height: min-content; justify-content: flex-start; overflow: visible; padding: 5px 30px 5px 30px; position: relative; text-decoration: none; width: 184px; }", ".framer-eQEYw .framer-18w0uxe { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-eQEYw.framer-j7znd0, .framer-eQEYw .framer-1w7njkr { gap: 0px; } .framer-eQEYw.framer-j7znd0 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-eQEYw.framer-j7znd0 > :first-child { margin-top: 0px; } .framer-eQEYw.framer-j7znd0 > :last-child { margin-bottom: 0px; } .framer-eQEYw .framer-1w7njkr > * { margin: 0px; margin-left: calc(6px / 2); margin-right: calc(6px / 2); } .framer-eQEYw .framer-1w7njkr > :first-child { margin-left: 0px; } .framer-eQEYw .framer-1w7njkr > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 34
 * @framerIntrinsicWidth 184
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"bpKZMzEZi":{"layout":["auto","auto"]}}}
 * @framerVariables {"Nv26bfu6G":"title","Y7o357GID":"tapStart"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerOOixDXDJK: React.ComponentType<Props> = withCSS(Component, css, "framer-eQEYw") as typeof Component;
export default FramerOOixDXDJK;

FramerOOixDXDJK.displayName = "FilterDropdownHover";

FramerOOixDXDJK.defaultProps = {height: 34, width: 184};

addPropertyControls(FramerOOixDXDJK, {Nv26bfu6G: {defaultValue: "text", displayTextArea: true, title: "Title", type: ControlType.String}, Y7o357GID: {title: "Tap Start", type: ControlType.EventHandler}} as any)

addFonts(FramerOOixDXDJK, [{explicitInter: true, fonts: [{family: "Brandon Text Bold", source: "custom", url: "https://framerusercontent.com/assets/O5k6Mj7wtZ00a6acvallq9qvPA.otf"}]}], {supportsExplicitInterCodegen: true})